import * as React from "react";
import { withLayout, LayoutProps } from "../components/Layout";
import {
  Button,
  Segment,
  Container,
  Grid,
  Header,
  Divider,
  Reveal,
  Card
} from "semantic-ui-react";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import ExpertiseCard from "../components/ExpertiseCard";

const IndexPage: React.SFC<LayoutProps> = ({ location: { pathname } }) =>
  <div>
    <SEO path={pathname} />
    {/* Master head */}
    <Segment vertical inverted textAlign="center" className="masthead">
      <Container>
        <Header inverted as="h1" style={{ fontSize: "3em" }}>We love building software!</Header>
        <Header inverted as="h2">
          We are a product focused team who wants to bring your vision to life, by building you a quality software product.
        </Header>
        <Link to={"/contact"}>
          <Button secondary size="huge">Let's work together!</Button>
        </Link>
      </Container>
    </Segment>

    <Container textAlign="center" style={{ marginTop: "2.5em", height: "inherit" }}>
      <Divider horizontal style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Header as='h1' style={{ fontSize: "2.5rem" }} >
          Our Expertise
        </Header>
      </Divider>
      <Grid container={true} relaxed="very" padded doubling stackable columns="equal" verticalAlign="middle" centered={true} >
        <Grid.Row>
          <ExpertiseCard
            icon="sitemap"
            title="Product Definition"
            description="We work with you to capture the vision for your product. Once we have the big picture, we can break it down into manageable pieces of work, based on user value, eventually, landing on the scope of your first release - the MVP." />
          <ExpertiseCard
            icon="code"
            title="Product Development"
            description="From the initial commit to DevOps configuration - and every code review, test, demo, revision, and tweak in between - we are ready to build your product, bringing your vision to life." />
          <ExpertiseCard
            icon="puzzle"
            title="Product Management"
            description="Keeping a prioritized backlog and creating well defined sprints is half the battle when keeping a team on track. Our focus on these aspects will ensure that the team is always adding the most value to your product." />
        </Grid.Row>
        <Grid.Row>
          <ExpertiseCard
            icon="sticky note outline"
            title="Agile &amp; Scrum Methodologies"
            description="Short sprints, daily stand-ups, reviews, and retrospectives help us all to stay on the same page. Regular demos, including the evaluation of the product and our process, allow us to pivot and adapt quickly to changing priorities." />
          <ExpertiseCard
            icon="mobile alternate"
            title="Web &amp; Mobile Apps" 
            description="We specialize in using React and React Native to build cross-platform applications. We are also ready to roll-up our sleeves and dig into native development to support products where necessary." />
          <ExpertiseCard
            icon="database"
            title="Full-Stack Development"
            description="From front-end UI elements to back-end APIs, we are comfortable and proficient in all areas of the development stack, allowing us to ensure that all aspects of your app function efficiently and effectively." />
        </Grid.Row>
      </Grid>
    </Container>
  </div >;

export default withLayout(IndexPage);
