import * as React from "react";
import {
  Grid,
  Header,
  Icon,
  SemanticICONS,
  Container,
  Popup,
  Reveal,
  Card,
  Segment
} from "semantic-ui-react";

interface ExpertiseCardProps {
  icon?: SemanticICONS;
  title: string;
  description?: string;
}


const ExpertiseCard: React.SFC<ExpertiseCardProps> = ({
  icon = "code",
  title,
  description = "Description of the expertise"
}) => {
  const body = (
    <Grid textAlign="center" padded="vertically" style={{ paddingBottom: 15, backgroundColor: "white" }} >
      <Grid.Row >
        <Icon name={icon} size="huge" style={{ paddingBottom: 10 }} />
      </Grid.Row>
      <Grid.Row>
        <Header textAlign="center" as={"h1"} className="small-caps">
          {title}
        </Header>
      </Grid.Row>
    </Grid>
  )

  return (
    <Grid.Column style={{ backgroundColor: "white", height: 200, textAlign:"center" }} >
        <Reveal animated="fade" style={{width:"100%", height:"100%", display:'flex',justifyContent:'center'}}>
          <Reveal.Content visible style={{width:"100%", height:"100%", backgroundColor:"white"}}>
            {body}
          </Reveal.Content>
          <Reveal.Content as={Segment} hidden style={{width:"100%", boxShadow: "unset"}}>
            <p style={{lineHeight: 1.75, textAlign: "left"}}>{description}</p>
          </Reveal.Content>
        </Reveal>
    </Grid.Column>
  )
}

export default ExpertiseCard; 
